/* eslint-disable import/prefer-default-export */
import React, { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
// import LeaderBoard from '../leadBoard/index';
import AuthRoutes from '../../components/AuthRoutes';
// import PlayerList from './PlayerList';
// import Teams from '../teams/index';
import Questions from '../questions/index';
// import Leagues from '../leagues/index';
import Loading from '../../components/Loading';
// import TeamDetails from '../teams/components/TeamDetails';
// import TeamEditView from '../teams/components/TeamEditView';
import QuestionDetails from '../questions/components/QuestionDetails';
import QuestionEditView from '../questions/components/QuestionEditView';
import SchoolsView from './school/components/SchoolsView';
import AdminPageWrapper from '../../layouts/AdminLayout/admin-page-wrapper';
import CurriculumView from './curriculum';

// import LeagueDetails from '../leagues/components/LeagueDetails';
// import LeagueEditView from '../leagues/components/LeagueEditView';

const AdminLayout = lazy(() => import('../../layouts/AdminLayout'));
const PorfilePage = lazy(() => import('./account'));
const EditProfilePage = lazy(() => import('./account/EditProfile'));
const Dashboard = lazy(() => import('./dashboard'));
const UserEditView = lazy(() => import('./users/components/UserEditView'));
const SchoolEditView = lazy(() => import('./school/components/SchoolEditView'));
const User = lazy(() => import('./users/index'));
const School = lazy(() => import('./school/index'));
const Annoucement = lazy(() => import('./annoucement/index'));
const ThemeQuizzes = lazy(() => import('./themedQuiz/index'));
const NotFoundView = lazy(() => import('../errors/NotFoundView'));

export const AdminRoutes = [
  {
    path: 'app',
    element: (
      <Suspense fallback={<Loading size={45} />}>
        <AdminLayout />
      </Suspense>
    ),
    children: [
      {
        path: 'dashboard',
        element: (
          <Suspense fallback={<Loading size={45} />}>
            <AdminPageWrapper
              pageTitle="Dashboard"
              page={<AuthRoutes component={Dashboard} />}
            />
          </Suspense>
        )
      },
      {
        path: 'users',
        element: (
          <Suspense fallback={<Loading size={45} />}>
            <AuthRoutes component={User} />
          </Suspense>
        )
      },
      {
        path: 'users/edit/:id',
        element: (
          <Suspense fallback={<Loading size={45} />}>
            <AdminPageWrapper
              pageTitle="Edit user"
              showBackButton
              page={<AuthRoutes component={UserEditView} />}
            />
          </Suspense>
        )
      },

      {
        path: 'questions',
        element: (
          <Suspense fallback={<Loading size={45} />}>
            <AuthRoutes component={Questions} />
          </Suspense>
        )
      },
      {
        path: 'questions/view/:id',
        element: (
          <Suspense fallback={<Loading size={45} />}>
            <AdminPageWrapper
              pageTitle="Question details"
              showBackButton
              page={<AuthRoutes component={QuestionDetails} />}
            />
          </Suspense>
        )
      },
      {
        path: 'questions/edit/:id',
        element: (
          <Suspense fallback={<Loading size={45} />}>
            <AdminPageWrapper
              pageTitle="Questions"
              showBackButton
              page={<AuthRoutes component={QuestionEditView} />}
            />
          </Suspense>
        )
      },
      {
        path: 'schools',
        element: (
          <Suspense fallback={<Loading size={45} />}>
            <AuthRoutes component={School} />
          </Suspense>
        )
      },
      {
        path: 'schools/view/:id',
        element: (
          <Suspense fallback={<Loading size={45} />}>
            <AuthRoutes component={SchoolsView} />
          </Suspense>
        )
      },
      {
        path: 'schools/edit/:id',
        element: (
          <Suspense fallback={<Loading size={45} />}>
            <AdminPageWrapper
              pageTitle="Edit School"
              showBackButton
              page={<AuthRoutes component={SchoolEditView} />}
            />
          </Suspense>
        )
      },

      {
        path: 'profile',
        element: (
          <Suspense fallback={<Loading size={45} />}>
            <AdminPageWrapper pageTitle="Account" page={<PorfilePage />} />
          </Suspense>
        )
      },

      {
        path: 'profile/edit',
        element: (
          <Suspense fallback={<Loading size={45} />}>
            <AdminPageWrapper
              pageTitle="Edit Profile"
              showBackButton
              page={<EditProfilePage />}
            />
          </Suspense>
        )
      },
      {
        path: 'annoucement',
        element: (
          <Suspense fallback={<Loading size={45} />}>
            <Annoucement />
          </Suspense>
        )
      },
      {
        path: 'curriculum',
        element: (
          <Suspense fallback={<Loading size={45} />}>
            <CurriculumView />
          </Suspense>
        )
      },
      {
        path: 'themedQuiz',
        element: (
          <Suspense fallback={<Loading size={45} />}>
            <ThemeQuizzes />
          </Suspense>
        )
      },
      {
        path: '404',
        element: (
          <Suspense fallback={<Loading size={45} />}>
            <NotFoundView />
          </Suspense>
        )
      },
      {
        path: '*',
        element: (
          <Suspense fallback={<Loading size={45} />}>
            <Navigate to="/app/404" />
          </Suspense>
        )
      }
    ]
  }
];
