/* eslint-disable no-shadow */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/function-component-definition */
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { useQuery } from '@tanstack/react-query';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import * as React from 'react';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import axios from '../../../clientProvider/baseConfig';
import Loading from '../../../components/Loading';
import { CustomModal, useModal } from '../../../components/Modal';
import topics from '../../../components/topics';
import AdminPageWrapper from '../../../layouts/AdminLayout/admin-page-wrapper';
import { Questions } from '../../../types';
import SyncExcelWorker from '../../AdminView/school/components/SyncExcelWorker';
import ToolbarSyncWorker from '../ToolbarSyncWorker';
import UploadDailyQuestion from '../UploadDailyQuestion';
import UploadImage from '../UploadImage';
import QuestionForm from './QuestionForm';
import SyncExcelWorkerProgress from '../../AdminView/school/components/SyncExcelWorkerProgress';

interface QuestionData {
  questions: Questions[];
  totalQuestions: number;
  totalPages: number;
}

interface Subject {
  subjectCode: number;
  label: string;
}

interface Topic {
  topicCode: number;
  topicCodeSimplified: number;
  topicDescription: string;
}

const QuestionsList: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [pageParam, setPageParam] = useQueryParam('page', NumberParam);
  const [levelParam, setLevelParam] = useQueryParam('level', NumberParam);
  const [subjectParam, setSubjectParam] = useQueryParam('subject', StringParam);
  const [topicParam, setTopicParam] = useQueryParam('topic', StringParam);
  const [typeParam, setTypeParam] = useQueryParam('type', StringParam);
  const [searchQuery, setSearchQuery] = useQueryParam('query', StringParam);
  const { open, handleClose } = useModal();
  const [totalQuestions, setTotalQuestions] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  const subjects = [
    { subjectCode: 1, label: 'Numbers and calculations' },
    { subjectCode: 2, label: 'Algebra' },
    { subjectCode: 3, label: 'Measurement' },
    { subjectCode: 4, label: 'Geometry' },
    { subjectCode: 5, label: 'Statistics' }
  ];

  const questionType = ['Narrative', 'Numerical', 'Visual', 'Text'];
  const levels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const fetchQuestions = async (
    searchQuery,
    levelParam,
    subjectParam,
    topicParam,
    typeParam,
    pageNumber = pageParam
  ) => {
    setIsLoading(true);
    try {
      // Set the base URL for the API endpoint with the limit and page number
      let url = `/Question/view_questions?limit=20&page=${pageNumber}`;

      // If there is a search query, add it to the URL as a query parameter
      if (searchQuery) {
        url += `&search=${searchQuery}`;
        // If the search query is not on the first page, reset the page number to 1
        if (searchQuery && pageParam !== 1) {
          setPageParam(pageNumber);
        } else {
          setPageParam(1);
        }
      }

      if (levelParam) {
        url += `&level=${levelParam}`;
        if (levelParam && pageParam !== 1) {
          setPageParam(pageNumber);
        } else {
          setPageParam(1);
        }
      }

      if (subjectParam) {
        url += `&subject=${subjectParam}`;
        if (subjectParam && pageParam !== 1) {
          setPageParam(pageNumber);
        } else {
          setPageParam(1);
        }
      }
      if (topicParam) {
        url += `&topic=${topicParam}`;
        if (topicParam && pageParam !== 1) {
          setPageParam(pageNumber);
        } else {
          setPageParam(1);
        }
      }

      if (typeParam) {
        url += `&type=${typeParam}`;
        if (typeParam && pageParam !== 1) {
          setPageParam(pageNumber);
        } else {
          setPageParam(1);
        }
      }
      // Fetch the questions from the API endpoint using axios
      const { data: response } = await axios.get(url);

      // Set the totalQuestions and totalPages state with the values from the API response
      setTotalQuestions(response.totalQuestions);
      setTotalPages(response.totalPages);

      // Set the isLoading state to false to hide the loading spinner or indicator
      setIsLoading(false);

      // If the page number is greater than the total number of pages, reset the page number to 1
      if (pageParam && pageParam > response.totalPages) {
        setPageParam(1);
      }

      // If the current page number is not the same as the page number provided, set the pageParam state with the new page number
      if (pageNumber !== pageParam) {
        setPageParam(pageNumber);
      }
      // Return the list of questions from the API response
      return response.questions;
    } catch (error) {
      // Log the error to the console and throw the error to be caught by the calling function or component
      console.error(error);
      throw error;
    }
  };

  const { data } = useQuery<QuestionData>(
    [
      'Teams',
      pageParam,
      searchQuery,
      levelParam,
      subjectParam,
      topicParam,
      typeParam
    ],
    () =>
      fetchQuestions(
        searchQuery,
        levelParam,
        subjectParam,
        topicParam,
        typeParam,
        pageParam
      )
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageParam(value); // Update the currentPage state
  };

  const handleReset = () => {
    // Set all the filter parameters to their initial values

    setIsLoading(true);
    setTimeout(() => {
      setSearchQuery('');
      setLevelParam(null);
      setSubjectParam(null);
      setTopicParam(null);
      setTypeParam(null);
      setPageParam(1);
      setIsLoading(false);
      setOpenFilter(false);
    }, 500);
  };

  const [openDailyModal, setOpenDailyModal] = React.useState<boolean>(false);
  const [openImageModal, setOpenImageModal] = React.useState<boolean>(false);
  const [openWorkerModal, setOpenWorkerModal] = React.useState<boolean>(false);
  const [syncModalOpen, setSyncModalOpen] = React.useState<boolean>(false);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [showSyncConfirmation, setShowSyncConfirmation] = React.useState(false);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(!true);
  };

  // const handleClickOpenSyncWorker = () => {
  //   setOpenWorkerModal(true);
  // };

  const handleCloseWorkerForm = () => {
    setOpenWorkerModal(!true);
  };

  const handleClickCloseDaily = () => {
    setOpenDailyModal(false);
  };

  const handleClickCloseImage = () => {
    setOpenImageModal(false);
  };

  const handleClickOpenSyncWorkerAlert = () => {
    setShowSyncConfirmation(true);
  };

  const handleConfirmSync = () => {
    setShowSyncConfirmation(false); // Close confirmation dialog
    if (!syncModalOpen) {
      setSyncModalOpen(true); // Open sync progress if not already open
    }
  };

  const handleCloseSyncModal = () => {
    setSyncModalOpen(false); // Close sync progress modal
  };

  const columns: MUIDataTableColumn[] = [
    {
      name: '_id',
      label: 'ID',
      options: {
        filter: false,
        display: 'false'
      }
    },

    {
      name: 'subject',
      label: 'Subject',
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: 'topic',
      label: 'Topic',
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: 'subTopic',
      label: 'Sub topic',
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: 'learningObjective',
      label: 'learning objective',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'questionNumber',
      label: 'Question number',
      options: {
        filter: true,
        sort: true
      }
    },

    {
      name: 'incorrectAnswers',
      label: 'Incorrect Answer 1',
      options: {
        customBodyRender: (tableMeta) => {
          return `${tableMeta[0]}`;
        }
      }
    },
    {
      name: 'incorrectAnswers',
      label: 'Incorrect Answer 2',
      options: {
        customBodyRender: (tableMeta) => {
          return `${tableMeta[1]}`;
        }
      }
    },
    {
      name: 'incorrectAnswers',
      label: 'Incorrect Answer 3',
      options: {
        customBodyRender: (tableMeta) => {
          return `${tableMeta[2]}`;
        }
      }
    },

    {
      name: 'descriptions.LOdescription',
      label: 'Learning Objective Description'
    },
    {
      name: 'question',
      label: 'Question'
    }
  ];

  const rightNodes = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        justifyContent: 'flex-end'
      }}
    >
      <ToolbarSyncWorker
        // handleClickOpenSyncWorker={handleClickOpenSyncWorker}
        handleClickOpenSyncWorker={handleClickOpenSyncWorkerAlert}
      />
    </div>
  );

  return (
    <AdminPageWrapper pageTitle="Question" rightNodes={rightNodes}>
      <div style={{ marginTop: '10px' }}>
        <CustomModal
          title="Add a new question"
          open={open}
          maxWidth="sm"
          handleClose={handleClose}
        >
          <QuestionForm handleClose={handleClose} />
        </CustomModal>
        <CustomModal
          title="Confirm Sync"
          open={showSyncConfirmation}
          maxWidth="sm"
          handleClose={() => setShowSyncConfirmation(false)}
        >
          <div style={{ padding: '16px' }}>
            <Typography>
              Are you sure you want to sync the questions?
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '16px'
            }}
          >
            <Button
              onClick={() => setShowSyncConfirmation(false)}
              variant="outlined"
              style={{ marginRight: '8px' }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmSync}
              variant="contained"
              color="primary"
            >
              Yes, Sync
            </Button>
          </div>
        </CustomModal>
        {/* Sync Progress Modal */}
        {syncModalOpen && (
          <SyncExcelWorkerProgress
            open={syncModalOpen}
            onClose={handleCloseSyncModal}
          />
        )}
        <CustomModal
          title="Sync Worker"
          subTitle=""
          open={openWorkerModal}
          maxWidth="sm"
          handleClose={handleCloseWorkerForm}
        >
          <SyncExcelWorker handleClose={handleCloseWorkerForm} />
        </CustomModal>
        <CustomModal
          title="Upload questions"
          open={openDailyModal}
          maxWidth="sm"
          handleClose={handleClickCloseDaily}
        >
          <UploadDailyQuestion handleClose={handleClickCloseDaily} />
        </CustomModal>
        <CustomModal
          title="Upload Image"
          open={openImageModal}
          maxWidth="sm"
          handleClose={handleClickCloseImage}
        >
          <UploadImage handleClose={handleClickCloseImage} />
        </CustomModal>

        {/* This component renders an input element with a search icon to allow users to enter search queries */}
        <div
          style={{ marginBottom: 10, display: 'flex', flexDirection: 'row' }}
        >
          <Input
            type="search"
            placeholder="Search..........."
            value={searchQuery}
            onChange={(e) => {
              // If the search query is at least one character long, set the pageParam state to 1
              if (e.target.value.length >= 0) {
                setPageParam(1);
              }
              // Set the searchQuery state with the new search query entered by the user
              setSearchQuery(e.target.value);
            }}
            style={{
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              marginRight: '8px',
              width: '26%'
            }}
            endAdornment={
              // Render a search icon on the right side of the input element
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
          />
          <Button
            sx={{ color: '#000' }}
            onClick={handleOpenFilter}
            startIcon={<FilterListIcon />}
          >
            {'  '} Filter
          </Button>
          <Button
            sx={{ color: 'red', fontWeight: 'bold' }}
            onClick={() => handleReset()}
          >
            {levelParam || subjectParam || topicParam || typeParam
              ? 'Clear Filters'
              : null}
          </Button>
          <Dialog open={openFilter} onClose={handleClose} fullWidth>
            <DialogTitle>Filter Questions</DialogTitle>
            <DialogContent
              sx={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignContent: 'center',
                flexDirection: 'column',
                gap: 6
              }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={levels}
                value={levelParam}
                sx={{ width: '100%', marginRight: '2%' }}
                onChange={(e: any, newValue: number | null | undefined) => {
                  if (newValue && newValue >= 0) {
                    setPageParam(1);
                  }
                  setLevelParam(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Level" variant="standard" />
                )}
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={subjects}
                value={
                  subjectParam
                    ? subjects.find(
                        (subject) => subject.subjectCode === +subjectParam
                      )
                    : null
                }
                sx={{ width: '100%', marginRight: '2%' }}
                onChange={(e: any, newValue: Subject | null | undefined) => {
                  if (newValue && newValue.label.length >= 0) {
                    setPageParam(1);
                  }
                  setSubjectParam(
                    newValue ? newValue.subjectCode.toString() : null
                  );
                }}
                getOptionLabel={(option: Subject) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label="Subject" variant="standard" />
                )}
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={topics}
                value={
                  topicParam
                    ? topics.find(
                        (topic) => topic.topicCodeSimplified === +topicParam
                      )
                    : null
                }
                sx={{ width: '100%' }}
                onChange={(e: any, newValue: Topic | null | undefined) => {
                  if (newValue && newValue.topicDescription.length >= 0) {
                    setPageParam(1);
                  }
                  setTopicParam(
                    newValue ? newValue.topicCodeSimplified.toString() : null
                  );
                }}
                getOptionLabel={(option: Topic) => option.topicDescription}
                renderInput={(params) => (
                  <TextField {...params} label="Topic" variant="standard" />
                )}
              />

              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={questionType}
                value={typeParam}
                sx={{ width: '100%' }}
                onChange={(e: any, newValue: string | null | undefined) => {
                  if (newValue && newValue.length >= 0) {
                    setPageParam(1);
                  }
                  setTypeParam(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Type" variant="standard" />
                )}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleCloseFilter()} variant="outlined">
                Close
              </Button>
              <Button
                sx={{ color: '#fff' }}
                onClick={() => handleCloseFilter()}
                variant="contained"
              >
                Show ({totalQuestions})
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {isLoading ? (
          <Loading size={40} />
        ) : (
          <>
            <MUIDataTable
              options={{
                elevation: 0,
                enableNestedDataAccess: '.',
                responsive: 'simple',
                // filterType: 'dropdown',
                filter: false,
                download: false,
                print: false,
                selectableRows: 'none',
                fixedHeader: true,
                rowsPerPage: 10,
                count: totalQuestions,
                pagination: false,
                tableBodyHeight: '500px',
                search: false,
                viewColumns: false
              }}
              title="Questions"
              columns={columns}
              data={data || []}
            />
            <div
              style={{
                paddingTop: 10
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#fff',
                  padding: 5,
                  marginBottom: 15
                }}
              >
                <Pagination
                  style={{ backgroundColor: '#fff', paddingRight: 20 }}
                  variant="outlined"
                  count={totalPages}
                  size="large"
                  color="primary"
                  showFirstButton
                  showLastButton
                  // @ts-ignore
                  page={pageParam}
                  onChange={handlePageChange}
                />
                <Typography style={{ marginLeft: '0 auto' }}>
                  Total Questions: {totalQuestions}
                </Typography>
              </div>
            </div>
          </>
        )}
      </div>
    </AdminPageWrapper>
  );
};

export default QuestionsList;
