import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import axios from '../../../../clientProvider/baseConfig';

interface SyncProgress {
  curriculum: string;
  message: string;
  totalQuestions: number | string;
  skippedQuestions: number | string;
  error?: string;
}

interface SyncModalProps {
  open: boolean;
  onClose: () => void;
}

const curriculums = ['ecz', 'cam', 'thm']; // Define this array outside the function to make it globally accessible

function SyncExcelWorkerProgress({ open, onClose }: SyncModalProps) {
  const [progress, setProgress] = useState<SyncProgress[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const initiateSync = async () => {
    setIsLoading(true);

    try {
      const results: SyncProgress[] = await Promise.all(
        curriculums.map(async (curriculum) => {
          try {
            const response = await axios.post(`/Question/sync_${curriculum}`);
            const curriculumKey = `${curriculum}Data`;
            const data = response.data[curriculumKey];

            return {
              curriculum: curriculum.toUpperCase(),
              message: data?.message || 'No message available',
              totalQuestions: data?.total ?? 'Not available',
              skippedQuestions: data?.totalSkipped ?? 'Not available',
              error: data?.message.includes('Quota exceeded')
                ? data.message
                : undefined
            };
          } catch (error) {
            // Handle the error case with default values for totalQuestions and skippedQuestions
            return {
              curriculum: curriculum.toUpperCase(),
              message: 'Error',
              error: (error as Error).message || 'An unexpected error occurred',
              totalQuestions: 'Not available',
              skippedQuestions: 'Not available'
            };
          }
        })
      );

      setProgress(results);
    } catch (error) {
      console.error('Error during sync initiation:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      setProgress([]); // Clear progress when the modal opens
      initiateSync();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Sync Progress</DialogTitle>
      <DialogContent>
        {progress.map((item) => (
          <React.Fragment key={item.curriculum}>
            <Typography variant="h6" gutterBottom>
              Curriculum: {item.curriculum}
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="Message" secondary={item.message} />
              </ListItem>
              {item.error ? (
                <ListItem>
                  <ListItemText primary="Error" secondary={item.error} />
                </ListItem>
              ) : (
                <>
                  <ListItem>
                    <ListItemText
                      primary="Total Questions"
                      secondary={item.totalQuestions}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Skipped Questions"
                      secondary={item.skippedQuestions}
                    />
                  </ListItem>
                </>
              )}
            </List>
            <Divider />
          </React.Fragment>
        ))}
        {isLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1rem'
            }}
          >
            <CircularProgress />
          </div>
        )}
        {!isLoading && progress.length === curriculums.length && (
          <Typography
            variant="body1"
            color="primary"
            align="center"
            style={{ marginTop: '1rem' }}
          >
            Sync Complete
          </Typography>
        )}
      </DialogContent>
      <Button onClick={onClose} color="primary" style={{ margin: '1rem' }}>
        Close
      </Button>
    </Dialog>
  );
}

export default SyncExcelWorkerProgress;
